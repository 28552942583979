<template>
<div>
    <v-container class=" history_box relative-center">
        <h1 class="mb-3">施工申込履歴</h1>
        <div class="float-right sort">
            <v-select v-model="sort" :items="sorts" item-text="name" item-value="id" />
        </div>
        <div class="clear" />
        <div v-show="customers.length==0" class="bold f-12 mt-10 f-orange">申込はありません</div>
        <v-card class="px-6 pt-2 pb-3 mb-6 relative" v-for="c in customers" :key="c.id" @click="open(c.orderNo)" :color="c.viewed==0?'yellow lighten-5':''">
            <div class="f-10 rounded deep-orange darken-1 f-white bold px-2 py-1 absolute t--5 l--5" v-show="c.viewed==0">
                new
            </div>
            <div class="f-09 text-right">（注文日時）{{moment(c.created_at).format("yyyy-MM-DD HH:mm")}}（注文番号）{{c.orderNo}}</div>
            <div class="bold text-left">施工希望日時<br><span class="f-12 f-orange">{{moment(c.day).format("yyyy年M月D日 HH:mm")}}</span></div>
            <div class="bold text-right"><span class="f-14">{{c.point}}<span class="pl-2 f-09">箇所</span></span></div>
            <div class="bold text-right f-12 f-10-xxs">合計金額(税込)<span class="f-22 f-20-xxs f-orange pl-3">{{(c.subtotal-c.discount).toLocaleString()}}<span class="f-12 pl-1">円</span></span></div>
            <div class="text-right f-09">({{c.payment}})</div>
            <v-btn color="light-blue darken-4" text class="mt-1 f-10">詳細を確認する</v-btn>
        </v-card>

    </v-container>
    <v-dialog width="550" v-model="dialog">
        <v-card class="px-6 pt-3 pb-6">
             <v-icon  class="float-right relative t-2 r-4 f-gray" @click="dialog=false">fas fa-times</v-icon>
             <div class="clear"/>
            <div class="f-09 text-left mt-1 relative l--10">（注文日時）{{moment(customer.created_at).format("yyyy-MM-DD HH:mm")}}（注文番号）{{customer.orderNo}}</div>
            <h2 class=" f-bgray mt-3 mb-6 f-14">
                施工箇所
                <hr class="relative-center title_hr" />
            </h2>
            <div v-for="o,i in orders" :key="i">
                <v-row class="f-10">
                    <v-col cols="6" xl="6" lg="6" md="6" sm="6" class="text-left">
                        <div class="bold pr-2">
                            <span v-if="o.step==2">フローリング</span>
                            <span v-if="o.step==3">和室（畳）</span>
                            <span v-if="o.step==5">廊下</span>
                            <span v-if="o.step==6">{{o.car}}</span>
                            <span v-if="o.step==7">トイレ</span>
                        </div>
                    </v-col>
                    <v-col cols="5" xl="3" lg="3" md="3" sm="3" class="text-left">
                        <div>
                            {{o.square.toLocaleString()}}
                            <span v-if="o.step==2||o.step==5||o.step==7">m<sup>2</sup></span>
                            <span v-if="o.step==3">畳</span>
                            <span v-if="o.step==6">台</span>
                        </div>
                        <div class="clear" />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="text-right">
                        ￥&nbsp;{{o.price.toLocaleString()}}
                    </v-col>
                </v-row>
            </div>

            <v-row class="f-10 f-red" v-if="customer.discount">
                <v-col cols="6" xl="6" lg="6" md="6" sm="6" class="text-left">
                    <div class="bold pr-2">
                        {{customer.target}}
                    </div>
                </v-col>
                <v-col cols="5" xl="3" lg="3" md="3" sm="3" class="text-left">
                    <div>
                        1
                        <span v-if="customer.target!='トイレ'">台</span>
                        <span v-if="customer.target=='トイレ'">m<sup>2</sup></span>
                    </div>
                    <div class="clear" />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="text-right">
                    -&nbsp;￥&nbsp;{{Number(customer.discount).toLocaleString()}}
                </v-col>
            </v-row>

            <div class="text-right mt-3">
                <div class=" bold mt-3">
                    <div class=" pr-2">
                        <span class="f-18 pr-1 f-orange">{{customer.point}}</span><span>箇所</span>
                    </div>
                    <div>
                        <span class="f-brown pr-2 f-11">お見積金額(合計)</span><span class="f-orange f-20">{{(customer.subtotal-customer.discount).toLocaleString()}}</span><span class="f-12">円<small class="pl-1">(税込)</small></span>
                    </div>
                    <div class="f-orange pr-2">{{customer.payment}}</div>
                </div>
            </div>
            <hr class="mx-2 mb-4 mt-2" />
            <h2 class=" f-bgray mb-2 f-14">
                <span v-if="Info.type==0">施工物件</span><span v-else>お客様</span>情報
                <hr class="relative-center title_hr" />
            </h2>
            <div class="text-left mb-4">
                <div class="f-brown">ご住所</div>
                <div class="bold f-bgray f-12" v-if="customer.zip">〒{{customer.zip}}</div>
                <div class="bold f-bgray f-12 mb-2">{{customer.address}}</div>

                <div class="f-brown">ご氏名</div>
                <div class="bold f-bgray f-12 mb-2">{{customer.name}}</div>

                <div class="f-brown">メールアドレス</div>
                <div class="bold f-bgray f-12 mb-2">{{customer.email}}</div>

                <div class="f-brown">お電話番号</div>
                <div class="bold f-bgray f-12 mb-2">{{customer.tel}}</div>
                <div v-if="customer.contact">
                    <div class="f-brown">備考</div>
                    <div class="bold f-bgray f-12 mb-2" style="white-space: pre-wrap;">{{customer.contact}}</div>
                </div>
            </div>

            <div class="rounded day_border bold pa-3">
                <div class="f-brown f-12">施工ご希望日時</div>
                <div class="f-orange f-16">{{moment(customer.day).format("yyyy年M月D日 HH:mm")}}</div>
                <div v-if="customer.station>0">
                    <div class="f-brown f-14 mt-6">施工場所</div>
                    <div class="f-orange f-14">
                        {{Station[Station.findIndex(({id}) => id === customer.station)].name}}
                    </div>
                    <div>
                        〒{{Station[Station.findIndex(({id}) => id === customer.station)].zip}}&nbsp;{{Station[Station.findIndex(({id}) => id === customer.station)].address}}{{Station[Station.findIndex(({id}) => id === customer.station)].address2}}
                    </div>
                    <div>
                        （<a :href="'https://www.google.com/maps/place/〒'+
                                Station[Station.findIndex(({id}) => id === customer.station)].zip+'+'+Station[Station.findIndex(({id}) => id === customer.station)].address+Station[Station.findIndex(({id}) => id === customer.station)].address2
                                " target="_blank">地図を開く</a>）
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import moment from "moment"
import Vue from "vue"
Vue.prototype.moment = moment

import axios from "axios"

import form from "@/mixins/form"

export default {
    mixins: [form],
    data() {
        return {
            dialog: false,
            customers: [],
            orders: [],
            customer: [],
            sorts: [{
                    id: 0,
                    name: "注文日時の新しい順",
                    column: "created_at",
                    order: "desc",
                },
                {
                    id: 1,
                    name: "注文日時の古い順",
                    column: "created_at",
                    order: "asc",
                },
                {
                    id: 2,
                    name: "施工日時の新しい順",
                    column: "day",
                    order: "desc",
                },
                {
                    id: 3,
                    name: "施工日時の古い順",
                    column: "day",
                    order: "asc",
                },

            ],
            sort: 0
        }
    },
    computed: {
        Station() {
            return this.$store.getters['user/station']
        },
    },
    async created() {
        const response = await axios.post("/user/order/customerLoad")
        if (response.data.customers) {
            this.customers = response.data.customers
        } else {
            alert("エラーが発生しました")
        }
    },
    methods: {
        async open(orderNo) {
            const data = {
                orderNo: orderNo
            }
            const response = await axios.post("/user/order/orderLoad", data)
            if (response.data.orders && response.data.customer) {
                this.customer = response.data.customer
                this.orders = response.data.orders
                this.dialog = true
            } else {
                alert("エラーが発生しました")
            }
        }
    },
    watch: {
        async sort(val) {
            const data = {
                column: this.sorts[val].column,
                order: this.sorts[val].order,
            }
            const response = await axios.post("/user/order/customerLoad", data)
            if (response.data.customers) {
                this.customers = response.data.customers
            } else {
                alert("エラーが発生しました")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/history.scss";
@import "@/assets/sass/order.scss";
</style>
